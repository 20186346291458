import * as Yup from 'yup';

import { AdminTokensEndpoints, AdminUserEndpoints, fetcher, useAdminGames, useAdminTournaments } from '@olagg/api-hooks'
import { Box, Button, Flex, Spinner, Switch, Text, VStack } from '@chakra-ui/react'
import { FC, useCallback, useEffect, useState } from 'react'
import {FormMiniGameData, formMiniGameSchema} from '@olagg/validation-schemas/miniGame.schemas'
import { TokenData, TournamentOrganizerData, TreasuresGame } from '@olagg/db-types'
import { convertUTCToLocalISOString, toLocalTimezone } from '../../utils';

import OlaggForm from '../../components/Forms/OlaggForm'
import OlaggFormInput from '../../components/Forms/OlaggFormInput'
import OlaggFormSelect from '../../components/Forms/OlaggFormSelect'
import OlaggMarkdown from '@olagg/ui-kit/OlaggMarkdown'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

type Instructions = {
  es?: string;
  en?: string;
  pt?: string;
}
  
interface IMiniGameFormProps {
  onSubmit: (dataForm: FormMiniGameData) => void;
  miniGame?: TreasuresGame;
};

const buildMiniGameInstructions = (miniGame?: TreasuresGame) => ({
  es: miniGame?.instructions || '',
  en: miniGame?.extraData?.en?.instructions || '',
  pt: miniGame?.extraData?.pt?.instructions || ''
}
)

const MiniGameForm: FC<IMiniGameFormProps> = ({ onSubmit, miniGame }) => {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [otherLangs, setOtherLangs] = useState<boolean>(miniGame?.extraData?.translated || false);
  const [staticOrganizers, setStaticOrganizers] = useState<any[]>([]);
  const [organizer, setOrganizer] = useState();
  const [miniGameInstructions, setMiniGameInstructions] = useState<Instructions>(buildMiniGameInstructions(miniGame));
  const networksArr = ['polygon', 'ronin', 'ethereum']
  const { fetchOrganizers } = useAdminTournaments()
  const [isLoading, setIsLoading] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState([])

  const useFormMethods = useForm<FormMiniGameData>({
    resolver: yupResolver(formMiniGameSchema),
    mode: 'onChange',
    defaultValues: {
      ...miniGame,
      visible: miniGame?.visible ?? false,
      autoRestart: miniGame?.autoRestart ?? false,
    },
  });

  const getOrganizerOptions = async () => fetchOrganizers().then((res) => {
    const organizersOptions = (Object.keys(res) as Array<keyof typeof res>).map((key) => {
      const organizer = res[key] as TournamentOrganizerData;
      return {
        value: key,
        label: organizer.name
      }
    })
    setStaticOrganizers(organizersOptions)

    if (miniGame?.organizer?.name) {
      const selectedOrganizer = organizersOptions.find(
        (option) => option.label === miniGame.organizer.name
      );

      if (selectedOrganizer) {
        setOrganizer(selectedOrganizer.value);
        useFormMethods.setValue('organizerId', selectedOrganizer.value);
      }
    }
  })

  const getUsers = useCallback(async () => {
    setIsLoading(true);
    await fetcher(
      AdminUserEndpoints.all({ page: 0, limit: 10 })
    ).then((response) => {
      const { users } = response;
      setFilteredUsers(users.map(user => ({
        value: user.id,
        label: `${user.name} (${user.email})`
      })));
    });

    setIsLoading(false);
  }, []);

  const handleSearchUser = async (inputValue) => {
    return await fetcher(
      AdminUserEndpoints.all({ page: 0, limit: 10, query: inputValue })
    ).then((response) => {
      const { users } = response;
      return users.map(user => ({
        value: user.id,
        label: `${user.name} (${user.email})`
      }));
    });
  };

  const convertToLocalTimeZone = () => {
    // Convert timezones
    if (!miniGame) return;

    const miniGameData: FormMiniGameData = { ...miniGame };

    miniGameData.startTime = toLocalTimezone(miniGameData.startTime);
    miniGameData.endTime = toLocalTimezone(miniGameData.endTime);
    useFormMethods.reset(miniGameData);
  }

  const organizerOption = staticOrganizers.find(
    option => option.label === miniGame?.organizer.name
  );
  
  useEffect(() => {
    getOrganizerOptions()
    convertToLocalTimeZone()
  }, [miniGame])

  useEffect(() => {
    getOrganizerOptions();
    getUsers();
  }, [])

  const beforeSubmit = () => {
    const formValues = useFormMethods.getValues();
  
    const sanitizedValues = {
      ...formValues,
      coverImageUrl: formValues.coverImageUrl ?? undefined,
      mapImageUrl: formValues.mapImageUrl ?? undefined,
      tilesImageUrl: formValues.tilesImageUrl ?? undefined,
      //startTime: convertUTCToLocalISOString(formValues.startTime),
      //endTime: convertUTCToLocalISOString(formValues.endTime)
    };
  
    onSubmit(sanitizedValues);
  };

  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={onSubmit}
      style={{
        width: '100%'
      }}
    >
      <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
        <Flex
          w="full"
          justify="start"
          align="center"
          style={{ marginTop: '30px' }}
        >
          <label
            htmlFor="enableShowLocale"
            style={{ cursor: 'pointer' }}
          >
            Otros idiomas
          </label>
          <Switch
            id="enableShowLocale"
            colorScheme="purple"
            isChecked={otherLangs}
            size="md"
            ml={2}
            onChange={() => setOtherLangs(prev => !prev)}
          />
        </Flex>
        <Box w='full'>
          <OlaggFormInput
            label='Título'
            controlName='title'
            required={true}
          />
          {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
            <OlaggFormInput
              label='Título Inglés'
              controlName='extraData.en.title'
            />
            <OlaggFormInput
              label='Título Portugués'
              controlName='extraData.pt.title'
            />
          </Flex>}

          <OlaggFormInput
            label='Descripción'
            controlName='lead'
            required={true}
          />
          {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
            <OlaggFormInput
              label='Descripción Inglés'
              controlName='extraData.en.lead'
            />
            <OlaggFormInput
              label='Descripción Portugués'
              controlName='extraData.pt.lead'
            />
          </Flex>}

          <OlaggFormInput
            label='Instrucciones'
            controlName='instructions'
            description='Escribe las instrucciones para el mini juego'
            inputType='textarea'
            required={true}
            onChange={(e) => setMiniGameInstructions({ ...miniGameInstructions, es: e.target.value })}
          />

          <Text lineHeight='normal' fontWeight={600} mb='2'>
            Instrucciones (Vista previa)
          </Text>
          <Box w='full' mb='5' border='1px solid white' minH='10' borderRadius='5' p='3'>
            <OlaggMarkdown>
              {miniGameInstructions.es || miniGameInstructions?.instructions || ''}
            </OlaggMarkdown>
          </Box>

          {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
            <OlaggFormInput
              label='Instrucciones Inglés'
              controlName='extraData.en.instructions'
              onChange={(e) => setMiniGameInstructions({ ...miniGameInstructions, en: e.target.value })}
            />
            <Text lineHeight='normal' fontWeight={600} mb='2'>
              Instrucciones Inglés (Vista previa)
            </Text>
            <Box w='full' mb='5' border='1px solid white' minH='10' borderRadius='5' p='3'>
              <OlaggMarkdown>
                {miniGameInstructions.en || miniGame?.extraData?.en?.instructions}
              </OlaggMarkdown>
            </Box>
            <OlaggFormInput
              label='Instrucciones Portugués'
              controlName='extraData.pt.instructions'
              onChange={(e) => setMiniGameInstructions({ ...miniGameInstructions, pt: e.target.value })}
            />
            <Text lineHeight='normal' fontWeight={600} mb='2'>
              Instrucciones Portugués (Vista previa)
            </Text>
            <Box w='full' mb='5' border='1px solid white' minH='10' borderRadius='5' p='3'>
              <OlaggMarkdown>
                {miniGameInstructions.pt || miniGame?.extraData?.pt?.instructions}
              </OlaggMarkdown>
            </Box>

          </Flex>}

          <OlaggFormInput
            label='Slug'
            controlName='slug'
            required={true}
          />

          <OlaggFormSelect
            label='Organizador'
            controlName='organizerId'
            required={true}
            isSearchable={true}
            staticOptions={staticOrganizers}
            newVersion
          />

          {isLoading ? (
            <Spinner />
          ) : (
            <OlaggFormSelect
              controlName="betaTesterIds"
              label="Usuarios BETA testers"
              description="Elige uno o mas usuarios"
              staticOptions={filteredUsers}
              asyncOptions={handleSearchUser}
              multiple={true}
              placeholder="Buscar por nombre, email o id"
              isSearchable={true}
              required={false}
              onChange={(selectedOptions) => {
                const ids = selectedOptions.map(option => option.value);
                useFormMethods.setValue('betaTesterIds', ids);
              }}
            />
          )}

          <OlaggFormInput
            label='Token'
            controlName='totalPrize[token]'
            placeholder='Token'
            required={true}
          />
          <OlaggFormInput
            label='Cantidad'
            controlName='totalPrize[amount]'
            placeholder='Cantidad'
            required={true}
          />
          <OlaggFormSelect
            label='Blockchain Network'
            controlName='totalPrize[network]'
            staticOptions={networksArr?.map(t => ({
                value: t,
                label: t
            }))}
            required={true}
            newVersion
          />

          <OlaggFormInput
            label='Imagen de portada'
            controlName='coverImageUrl'
            description='URL de la imagen para mostrar en pre-visualización.'
          />
          {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
            <OlaggFormInput
              label='Imagen de portada Inglés'
              controlName='extraData.en.coverImageUrl'
            />
            <OlaggFormInput
              label='Imagen de portada Portugués'
              controlName='extraData.pt.coverImageUrl'
            />

          </Flex>}

          <OlaggFormInput
            label='Imagen del mapa'
            controlName='mapImageUrl'
            description='URL de la imagen del mapa.'
            required={false}
          />
          {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
            <OlaggFormInput
              label='Imagen del mapa Inglés'
              controlName='extraData.en.mapImageUrl'
            />
            <OlaggFormInput
              label='Imagen del mapa Portugués'
              controlName='extraData.pt.mapImageUrl'
            />

          </Flex>}

          <OlaggFormInput
            label='Imagen de tiles'
            controlName='tilesImageUrl'
            description='Debe ser una imagen de las mismas dimensiones del mapa. Esta imagen se sobrepone al mapa y se muestra como el background de todos los Tiles aun no descubiertos.'
            required={false}
          />
          {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
            <OlaggFormInput
              label='Imagen de tiles Inglés'
              controlName='extraData.en.tilesImageUrl'
            />
            <OlaggFormInput
              label='Imagen de tiles Portugués'
              controlName='extraData.pt.tilesImageUrl'
            />

          </Flex>}

          <OlaggFormInput
            label='Número de filas'
            controlName='rows'
            description='Debe ser menor o igual que el height de la imagen del mapa dividido entre 50 px'
            required={true}
            disabled={miniGame?.status === 'active' ? true : false}
          />

          <OlaggFormInput
            label='Número de columnas'
            controlName='columns'
            description='Debe ser menor que el width de la imagen del mapa dividido entre 50 px'
            required={true}
            disabled={miniGame?.status === 'active' ? true : false}
          />

          <OlaggFormInput
            label='Tiempo de espera'
            controlName='waitTime'
            description='Tiempo en segundos a esperar entre cada oportunidad.'
            required={true}
          />

          <OlaggFormInput
            label='Umbral mínimo de XP'
            controlName='minXpThreshold'
            description='Cuantos XP debe acumular como minimo durante el tiempo de espera para poder tener una oportunidad.'
            required={true}
          />

          <OlaggFormInput
            label='Level mínimo para participar'
            controlName='minLevel'
            description='El nivel mínimo que debe tener para participar.'
            required={true}
          />

          <OlaggFormInput
            label='Fecha de inicio del mini juego'
            controlName='startTime'
            inputType='datetime'
            description={`Zona horaria: ${currentTimeZone}`}
            datePickerProps={{ backgroundColor: 'white' }}
            required={true}
          />

          <OlaggFormInput
            label='Fecha de finalización del mini juego'
            controlName='endTime'
            inputType='datetime'
            description={`Zona horaria: ${currentTimeZone}`}
            datePickerProps={{ backgroundColor: 'white' }}
            required={true}
          />

          <OlaggFormInput
            label='Cantidad de tesoros'
            controlName='totalTreasures'
            description='Debe ser menor que el total de número de tiles.'
            required={true}
            disabled={miniGame?.status === 'active' ? true : false}
          />

          <OlaggFormInput
            label='Visible'
            controlName='visible'
            inputType='switch'
            required={false}
          />

          <OlaggFormInput
            label='Reiniciar partida cuando termina'
            controlName='autoRestart'
            inputType='switch'
            required={false}
            disabled={ miniGame ? true : false}
          />
        </Box>

        <Button
          onClick={beforeSubmit}
          variant={'filledGradient'}
          type="button"
          w={'full'}
          style={{
            marginTop: '30px'
          }}
        >
          Guardar
        </Button>

      </VStack>
    </OlaggForm>
  )
}

export default MiniGameForm;
