import * as Yup from 'yup';

import type { InferType } from 'yup';

export const formMiniGameSchema = Yup.object({
  title: Yup
    .string()
    .required('Introduce el nombre del mini juego'),
  lead: Yup
    .string()
    .required('Introduce la descripción del mini juego'),
  instructions: Yup
    .string()
    .required('Introduce las instrucciones del mini juego'),
  slug: Yup
    .string()
    .matches(/^\S*$/, 'El slug no puede contener espacios en blanco')
    .required('Introduce el slug del mini juego'),
  startTime: Yup
    .string()
    .required('Introduce la hora de inicio del mini juego'),
  endTime: Yup
    .string()
    .required('Introduce la hora de finalización del mini juego'),
  coverImageUrl: Yup
    .string()
    .url('Debe ser una URL válida para la imagen de portada')
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  mapImageUrl: Yup
    .string()
    .url('Debe ser una URL válida para la imagen del mapa')
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  tilesImageUrl: Yup
    .string()
    .url('Debe ser una URL válida para la imagen de las teselas')
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  rows: Yup
    .number()
    .required('Introduce el número de filas'),
  columns: Yup
    .number()
    .required('Introduce el número de columnas'),
  totalPrize: Yup.object({
    token: Yup.string().required('Introduce el token del premio total'),
    amount: Yup.string().required('Introduce la cantidad del premio total'),
    network: Yup.string().required('Introduce la red del premio total'),
  }).required('Introduce el premio total del mini juego'),
  totalTreasures: Yup
    .number()
    .required('Introduce el número total de tesoros'),
  waitTime: Yup
    .number()
    .required('Introduce el tiempo de espera'),
  minXpThreshold: Yup
    .number()
    .required('Introduce el umbral mínimo de experiencia'),
  minLevel: Yup
    .number()
    .required('Introduce el nivel mínimo requerido'),
  organizerId: Yup
    .string()
    .required('Introduce el ID del organizador'),
  visible: Yup
    .boolean()
    .default(false)
    .nullable(),
  autoRestart: Yup
    .boolean()
    .default(false)
    .nullable(),
  extraData: Yup
    .object(),
  betaTesterIds: Yup
    .array()
});

export type FormMiniGameData = InferType<typeof formMiniGameSchema>;