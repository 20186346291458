export const ROUTES = {
  LOGIN: '/login',
  HOME: '/home',
  SCHOLARSHIP_APPLICATIONS: '/solicitudes',
  GAMES: {
    all: '/juegos',
    create: '/juegos/crear',
    content: '/juegos/contenido',
    content_update: '/juegos/contenido/editar',
    content_create: '/juegos/contenido/crear',
    update: '/juegos/:slug',
  },
  CREDENTIALS: {
    all: '/becas',
    create: '/becas/crear',
    update: '/becas/:id'
  },
  USERS: {
    all: '/usuarios',
    show: '/usuarios/:id'
  },
  OWNERS: {
    all: '/owners',
    create: '/owners/crear',
    show: '/owners/:id'
  },
  QUESTS: {
    all: '/quest',
    create: '/quest/crear',
    batch_complete: '/quest/batch_complete/:id',
    show: '/quest/:id',
  },
  USER_CONTENT: {
    all: '/user-content',
    create: '/user-content/crear',
    show: '/user-content/:id'
  },
  NOTIFICATIONS: {
    all: '/notifications',
    create: '/notifications/create',
  },
  SEGMENTS: {
    all: '/segments',
    create: '/segments/create',
    assignDevice: '/segments/assign_device',
  },
  DEVICES: {
    all: '/devices',
  },
  MARKETPLACE: {
    all: '/tienda',
    create: '/tienda/crear',
    update: '/tienda/:id'
  },
  TOURNAMENTS: {
    all: '/tournaments',
    create: '/tournaments/crear',
    update: '/tournaments/:id',
    inscriptions: '/tournaments/:id/inscriptions',
    addInscription: '/tournaments/:id/inscriptions/add',
  },
  TOKENS: {
    all: '/tokens',
    create: '/tokens/crear',
    update: '/tokens/:id',
    whitelist: '/tokens/:tokenId/assignments',
    addWhitelistedUser: '/tokens/:id/whitelist/add',
    addWhitelistedUsers: '/tokens/:id/whitelist/batch',
  },
  RELEVANT_ACTIONS: {
    all: '/relevant_actions',
    create: '/relevant_actions/crear',
    update: '/relevant_actions/:id',
  },
  ALERTS: {
    all: '/alerts',
    create: '/alerts/crear',
    update: '/alerts/:id',
  },
  USER_PERMISSIONS: {
    all: '/permisos',
  },
  ROLES: {
    all: '/permisos/roles',
    create: '/permisos/roles/crear',
    update: '/permisos/roles/:id',
  },
  ROLES_PERMISSIONS: {
    all: '/permisos/permisos_de_rol',
    create: '/permisos/permisos_de_rol/crear',
    update: '/permisos/permisos_de_rol/:id',
  },
  ROULETTE: {
    all: '/ruleta'
  },
  TOKEN_DROPS: {
    all: '/token-drops',
    create: '/token-drops/crear',
    update: '/token-drops/:id',
  },
  PAYMENTS: {
    all: '/payments',
    create: '/payments/crear',
    update: '/payments/:id',
  },
  MINI_GAMES: {
    all: '/mini_games',
    create: '/mini_games/crear',
    update: '/mini_games/:id',
  },
}
